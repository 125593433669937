import React, { useState } from 'react';
import styles from './Tabs.scss';
import clsx from 'clsx';
const Tabs = ({ list, activeTab, setActiveTab }) => {
  return (
    <>
      <div class="dm-tab tab-horizontal">
        <ul class="nav nav-tabs vertical-tabs" role="tablist">
          {list?.map((item) => (
            <li
              key={item.value}
              class="nav-item"
              onClick={() => {
                setActiveTab(item?.value);
              }}>
              <div class={clsx('nav-link', activeTab === item.value && 'active')} id="tab-v-1-tab" data-bs-toggle="tab" href="#tab-v-1" role="tab" aria-selected="true">
                {item?.label}
              </div>
            </li>
          ))}
        </ul>
        <div class="tab-content mt-35">{list?.find((item) => item.value == activeTab)?.component}</div>
      </div>
    </>
  );
};

export default Tabs;
