import React from 'react';
import './Tab6.scss';
import StatCardList from '../../site/common/StatCardList/StatCardList';
import StatInlineList from '../../site/common/StatInlineList/StatInlineList';
import Table from '../../site/common/Table/Table';
import Button from '../../site/Button/Button';
const Tab6 = () => {
  const list1 = [
    {
      label: 'Остаток по Алмате',
      value: 'на 1 000 000 ₸',
      caption: '50 ед - 50 %',
      bigMargin: true,
    },
  ];
  const headers = ['Группа', 'Артикул продавца', 'Наименовнаие', 'Продано', 'На сумму'];

  const listTable = [
    { color: '#fff', bgColor: '#01B81A', row: ['А', '3094020924_98953893', 'Logitech черный', '7 шт.', '62 293 '] },
    { bgColor: '#FFE500', row: ['А', '3094020924_98953893', 'Logitech черный', '7 шт.', '62 293 '] },
    { bgColor: '#FFE500', row: ['А', '3094020924_98953893', 'Logitech черный', '7 шт.', '62 293 '] },
    { bgColor: '#FA8B0C', row: ['А', '3094020924_98953893', 'Logitech черный', '7 шт.', '62 293 '] },
  ];

  return (
    <>
      <StatInlineList list={list1} className="container-fluid" big />

      <Table className="mt-20" headers={headers} list={listTable} />
    </>
  );
};

export default Tab6;
