import React, { useEffect, useState } from 'react';
import styles from './HomeLayout.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Loading/Loading';
import { userAuth } from '../../../redux/actions/user/userAuth';
import { userProfile } from '../../../redux/actions/user/userProfile';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetUserAuth, resetUserProfile } from '../../../redux/slices/user.slice';
const menuData = [
  // {
  //   label: "Главная",
  //   icon: "uil-chart",
  //   link: "/overview",
  // },
  {
    label: 'Профиль',
    icon: 'uil-user',
    link: '/account/edit',
  },
  {
    label: 'Тарифы',
    icon: 'uil-bill',
    link: '/pricing',
  },
  {
    label: 'Документы',
    icon: 'uil-bill',
    link: '/documents',
  },
  {
    blank: true,
    label: 'Поддержка',
    icon: 'uil-headphones',
    link: 'https://wa.me/77088460972',
  },
  {
    label: 'Настройки',
    icon: 'uil-setting',
    link: '/account/setting',
  },
];

const HomeLayout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(true);
  const { pathname } = useLocation();
  const {
    userAuth: { data: userData, loading: userLoading, error: userError },
  } = useSelector((state) => state.user);
  useEffect(() => {
    if (userData) {
    }
  }, []);
  const dipsatch = useDispatch();
  useEffect(() => {
    dipsatch(userAuth());
    dipsatch(userProfile());
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (userError) {
      localStorage.removeItem('token');
      dipsatch(resetUserAuth());
      dipsatch(resetUserProfile());
      navigate('/login');
    }
  }, [userError]);

  return userData ? (
    <>
      <ToastContainer theme="colored" />
      <div class="mobile-search">
        <form action="/" class="search-form">
          <img src="/img/svg/search.svg" alt="search" class="svg" />
          <input class="form-control me-sm-2 box-shadow-none" type="search" placeholder="Search..." aria-label="Search" />
        </form>
      </div>
      <div class="mobile-author-actions"></div>
      <header class="header-top">
        <nav class="navbar navbar-light">
          <div class="navbar-left">
            <div class="logo-area">
              <Link class="navbar-brand" to="/">
                <img class="dark" src="/img/logo1.png" alt="logo" />
                <img class="light" src="/img/logo1.png" alt="logo" />
              </Link>
              <a
                href="#"
                class="sidebar-toggle"
                onClick={() => {
                  setShowMenu(!showMenu);
                }}>
                <img class="svg" src="/img/svg/align-center-alt.svg" alt="img" />
              </a>
            </div>
          </div>

          <div class="navbar-right">
            <ul class="navbar-right__menu">
              <li class="nav-author">
                <div class="dropdown-custom">
                  <a href="javascript:;" class="nav-item-toggle">
                    <span class="avatar avatar-primary avatar-sm avatar-circle   avatar-transparent ">
                      <span class="avatar-letter">{userData?.email?.substring(0, 1)}</span>
                    </span>
                    <span class="nav-item__title ml-0" style={{ textTransform: 'none' }}>
                      {userData?.email}
                      <i class="las la-angle-down nav-item__arrow"></i>
                    </span>
                  </a>
                  <div class="dropdown-parent-wrapper">
                    <div class="dropdown-wrapper">
                      <div class="nav-author__options">
                        <ul>
                          {menuData?.map((item) => (
                            <li>
                              <Link to={item?.link}>
                                <i class={`uil ${item?.icon}`}></i> {item?.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        <div
                          href=""
                          class="nav-author__signout"
                          onClick={() => {
                            localStorage.removeItem('token');
                            dipsatch(resetUserAuth());
                            dipsatch(resetUserProfile());
                            navigate('/login');
                          }}>
                          <i class="uil uil-sign-out-alt"></i> Выход
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <main class="main-content">
        <div class="sidebar-wrapper">
          <div class={`sidebar sidebar-collapse ${!showMenu ? 'collapsed' : ''}`} id="sidebar">
            <div class="sidebar__menu-group">
              <ul class="sidebar_nav">
                {menuData?.map((item) => (
                  <li>
                    <Link to={item?.link} class={pathname == item?.link ? 'active' : ''}>
                      <span class={`nav-icon uil ${item?.icon}`}></span>
                      <span class="menu-text">{item?.label}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* {showMenu ? (
              <a
                className="ext"
                style={{ display: "block" }}
                href="https://chromewebstore.google.com/detail/sellstat-%D0%B2%D0%B0%D1%88-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%B2-%D0%B0/hffbedinjbkibpagebmimleihdilkiae?hl=ru"
              >
                <div className="ext-open">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="var(--color-primary)"
                  >
                    <path d="M18,10.82a1,1,0,0,0-1,1V19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8A1,1,0,0,1,5,7h7.18a1,1,0,0,0,0-2H5A3,3,0,0,0,2,8V19a3,3,0,0,0,3,3H16a3,3,0,0,0,3-3V11.82A1,1,0,0,0,18,10.82Zm3.92-8.2a1,1,0,0,0-.54-.54A1,1,0,0,0,21,2H15a1,1,0,0,0,0,2h3.59L8.29,14.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L20,5.41V9a1,1,0,0,0,2,0V3A1,1,0,0,0,21.92,2.62Z"></path>
                  </svg>
                </div>
                <div className="ext-header">
                  <img
                    src="img/google-chrome.svg"
                    style={{ width: "16px", height: "16px" }}
                  />
                  <span
                    class="badge badge-round badge-primary badge-lg"
                    style={{
                      fontSize: "11px",
                      height: "18px",
                      padding: "0 6px",
                    }}
                  >
                    {" "}
                    Google Chrome Extension
                  </span>
                </div>
                <div className="ext-body">
                  Наше расширение SellStat в Google Chrome. Для работы с
                  Аналитикой и Демпингом товара.
                </div>
              </a>
            ) : (
              <a
                href="https://chromewebstore.google.com/detail/sellstat-%D0%B2%D0%B0%D1%88-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%B2-%D0%B0/hffbedinjbkibpagebmimleihdilkiae?hl=ru"
                className=""
                style={{
                  display: "block",
                  width: "42px",
                  height: " 42px",
                  borderRadius: "50%",
                  padding: "15px 0",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
              >
                {" "}
                <img
                  src="img/google-chrome.svg"
                  style={{
                    width: "18px",
                    height: "18px",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </a>
            )} */}
          </div>
        </div>

        <div class={`contents ${!showMenu ? 'expanded' : ''}`}>{children}</div>
        {/* <footer class="footer-wrapper">
          <div class="footer-wrapper__inside">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <div class="footer-copyright">
                    <p>
                      <span>© 2023</span>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="footer-menu text-end">
                    <ul>
                      <li>
                        <a href="#">About</a>
                      </li>
                      <li>
                        <a href="#">Team</a>
                      </li>
                      <li>
                        <a href="#">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer> */}
      </main>
    </>
  ) : userLoading ? (
    <Loading isWhite />
  ) : (
    <Loading isWhite />
  );
};

export default HomeLayout;
