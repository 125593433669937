import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../Input/Input";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import Loading from "../Loading/Loading";
import { Helmet } from "react-helmet";
import {
  setPhoneNumber,
  setStepRegistration,
} from "../../../redux/slices/app.slice";
import {
  resetUserConfirmCode,
  resetUserResendCode,
} from "../../../redux/slices/user.slice";
import { useMutation } from "@tanstack/react-query";
import { registration } from "../../../api";

export const Registration = () => {
  const registrationForm = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate, isPending } = useMutation({
    mutationFn: registration,
    onSuccess: (response) => {
      localStorage.setItem("token", response.access);
      navigate("/documents");
    },
  });

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <>
      <Helmet>
        <title>Регистрация</title>
      </Helmet>
      <div class="card border-0">
        <div class="card-header">
          <div class="edit-profile__title">
            <h4>Регистрация</h4>
          </div>
        </div>
        <div class="card-body">
          <div class="edit-profile__body">
            <div class="edit-profile__body">
              <Input
                form={registrationForm}
                name="email"
                type="text"
                label="Почта"
                classWrap="mb-25"
                rules={{
                  required: { message: "Обязательное поле", value: true },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Неверный формат почты",
                  },
                }}
              />
              <Input
                form={registrationForm}
                name="password1"
                type="password"
                label="Пароль"
                classWrap="mb-25"
                rules={{
                  required: { message: "Обязательное поле", value: true },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/i,
                    message:
                      "Пароль должен содержать минимум: \n - Одну заглавную букву \n- Одну маленькую букву \n- Одну цифру \n- Длинну более 8 символов ",
                  },
                }}
              />
              <Input
                form={registrationForm}
                name="password2"
                type="password"
                label="Повторите пароль"
                classWrap="mb-10"
                rules={{
                  required: { message: "Обязательное поле", value: true },
                  validate: (val) => {
                    if (registrationForm.watch("password1") !== val) {
                      return "Пароли не совпадают";
                    }
                  },
                }}
              />
              <div className="mb-25" style={{ fontSize: "12px" }}>
                Нажимая кнопку "зарегистрироваться" вы соглашаетесь с{" "}
                <a href="/privacy.pdf" target="_blank">
                  политикой конфиденциальности
                </a>
                &nbsp;и&nbsp;
                <a href="/ofert.pdf" target="_blank">
                  договором&nbsp;офертой
                </a>
              </div>
              <div class="admin__button-group button-group d-flex pt-1 justify-content-md-start justify-content-center">
                <Button
                  className="text-capitalize lh-normal px-50 signIn-createBtn "
                  style={{ margin: "0 auto" }}
                  onClick={registrationForm.handleSubmit(onSubmit)}
                >
                  Зарегистрироваться
                </Button>
              </div>
            </div>
          </div>
        </div>
        <p class="mb-30 d-flex align-items-center justify-content-center">
          Уже есть аккаунт?&nbsp;
          <Link
            to="/login"
            class="color-primary"
            onClick={() => {
              dispatch(setPhoneNumber(null));
              dispatch(setStepRegistration(0));
              dispatch(resetUserResendCode());
              dispatch(resetUserConfirmCode());
            }}
          >
            Войти
          </Link>
        </p>
        {isPending && <Loading />}
      </div>
    </>
  );
};
