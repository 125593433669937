import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import styles from './Lessons.module.scss';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getLessons, saveLessons } from '../../../api';
import { Button, Input, Spin } from 'antd';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
const Lessons = ({ id, loading, fetchDoc }) => {
  const {
    data,
    isLoading: isLessonsLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['lessons', id],
    queryFn: () => getLessons(id),
  });
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: saveLessons,
    onSuccess: () => {
      fetchDoc();
      queryClient.invalidateQueries({ queryKey: ['saveLessons', id] });
    },
  });
  const { control, register, handleSubmit } = useForm();
  const { fields, replace, remove, append } = useFieldArray({
    control,
    name: 'lessons',
  });
  const onSubmit = (data) => {
    mutate({ id, lessons: data.lessons });
  };
  useEffect(() => {
    if (data) {
      replace(data);
    }
  }, [data]);

  return (
    <>
      {isLessonsLoading || isRefetching || isPending || loading ? (
        <Spin style={{ marginLeft: '180px', marginTop: '10px' }} />
      ) : (
        <div className={clsx(styles.wrap)}>
          {fields?.map((field, index) => (
            <div className={clsx(styles.item)} key={field.id}>
              <Controller rules={{ required: true }} name={`lessons.${index}`} control={control} render={({ field }) => <Input {...field} />} />

              <div
                className={clsx('nav-icon uil uil-trash', styles.delete)}
                onClick={() => {
                  remove(index);
                }}></div>
              {/* </> */}
            </div>
          ))}
          <div
            className={clsx('nav-icon uil uil-plus-circle', styles.add)}
            onClick={() => {
              append(`Новый урок ${fields?.length + 1}`);
            }}></div>
          <div className={clsx(styles.btns)}>
            <Button
              type="primary"
              onClick={() => {
                refetch();
              }}>
              Сгенерировать заново
            </Button>
            <Button type="primary" onClick={handleSubmit(onSubmit)} className="bg-success text-white" loading={isPending}>
              Сохранить
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Lessons;
