import axios from "axios";

const token = localStorage.getItem("token");

export const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const pureApi = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API,
});
