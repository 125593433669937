import { useParams } from 'react-router-dom';
import { List, Descriptions, Card, Spin, Button, Flex } from 'antd';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Markdown from 'react-markdown';
import { downloadFile, getDocument, regenerateBlock } from '../../../api';
import { RegenerateWithPrompt } from '../../../components/site/RegenerateWithPrompt/RegenerateWithPrompt';
import remarkGfm from 'remark-gfm';
import styles from './DocumentPage.module.scss';
import Lessons from '../Lessons/Lessons';
import { useEffect, useState } from 'react';
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const DocumentPage = () => {
  let { id } = useParams();

  const queryClient = useQueryClient();
  const [startPooling, setStartPooling] = useState(null);
  const {
    data,
    isLoading: isPageLoading,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ['pages', id],
    queryFn: () => getDocument(id),

    refetchInterval: (data) => {
      return data.state.data?.done || (data.state.data?.template?.needed_init && !data.state.data?.is_approved) ? undefined : 5000;
    },
  });
  // useEffect(() => {
  //   if (data) {
  //     const refetchData = async () => {
  //       await sleep(5000);
  //       refetch();
  //     };
  //     refetchData();
  //     // if ((data?.template?.needed_init && data?.is_approved) || !data?.template?.needed_init) {
  //     //   setStartPooling(true);
  //     // }
  //     // if (data?.template?.needed_init && !data?.is_approved) {
  //     //   setStartPooling(false);
  //     // }
  //   }
  // }, [data]);

  const { mutate, isPending: isRegeneratePending } = useMutation({
    mutationFn: regenerateBlock,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pages', id] });
    },
  });

  const { mutate: download, isPending: isDownloadPending } = useMutation({
    mutationFn: downloadFile,
    onSuccess: (response) => {
      window.open(response.url, '_blank');
    },
  });

  if (isPageLoading) {
    return <Spin />;
  }

  return (
    data && (
      <div className="App" style={{ marginTop: 32 }}>
        <Descriptions title={`Документ #${id}`}>
          <Descriptions.Item label="Тема">{data.topic}</Descriptions.Item>
          <Descriptions.Item label="Предмет">{data.subject}</Descriptions.Item>
          <Descriptions.Item label="Класс">{data.grade}</Descriptions.Item>
          <Descriptions.Item label="Шаблон">{data.template.title}</Descriptions.Item>
        </Descriptions>
        {/* {console.log(!!(data?.template?.nedded_init && !data?.is_approved))} */}

        {data?.template?.needed_init && !data?.is_approved ? (
          <Lessons id={id} fetchDoc={refetch} loading={isPageLoading || isRefetching} />
        ) : data.done ? (
          'Создание документа завершено'
        ) : !data.done || isRefetching ? (
          <>
            Документ в обработке...
            <Spin />
          </>
        ) : (
          <></>
        )}
        {data.done || data?.blocks?.length ? (
          <>
            {' '}
            <List
              className={styles.document}
              itemLayout="horizontal"
              dataSource={data.blocks.filter((block) => block.block.show)}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    description={
                      <>
                        <Card
                          title={item.block.title}
                          loading={!item.text}
                          extra={
                            <Flex gap={16}>
                              {!!item.text && (
                                <Button loading={isRegeneratePending} onClick={() => mutate(item.id)}>
                                  Пересоздать
                                </Button>
                              )}
                              {!!item.text && <RegenerateWithPrompt pageId={id} blockId={item.id} />}
                            </Flex>
                          }>
                          <Markdown remarkPlugins={[remarkGfm]}>{item.text}</Markdown>
                        </Card>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
            <Button loading={isDownloadPending} onClick={() => download(id)}>
              Скачать DOCX
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
    )
  );
};
