import React, { useEffect } from 'react';
import styles from './OverviewPage.module.scss';
import TotalLineChart from '../../../components/site/TotalSales/TotalSales';
import { useDispatch, useSelector } from 'react-redux';
import OverviewItem from '../../../components/site/OverviewItem/OverviewItem';
import { Helmet } from 'react-helmet';
import { userStatistics } from '../../../redux/actions/user/userStatistics';
import { userStatisticMonth } from '../../../redux/actions/user/userStatisticMonth';
import OverviewMonth from '../OverviewMonth/OverviewMonth';
import OverviewPartnerDashboard from '../../../components/site/OverviewPartnerDashboard/OverviewPartnerDashboard';
import { currencyFormat } from '../../../utils/currencyFormat';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Steps from '../../../components/site/Steps/Steps';
import Video from '../../../components/site/Video/Video';
const OverviewPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userStatistics());
  }, []);
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: 'img/video.mp4',
        type: 'video/mp4',
      },
    ],
  };
  const {
    userProfile: { data: userProfile },
    userStatistics: { data: dataUserStatistics, loading: loadingUserStatistics },
  } = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <>
      {userProfile?.partner && (
        <>
          {' '}
          <div class="crm demo6 " style={{ marginBottom: '35px' }}>
            <div class="container-fluid">
              <div class="row ">
                <div class="col-lg-12">
                  <div class="breadcrumb-main justify-content-center mb-20">
                    <h4 class=" breadcrumb-title text-center">Общая статистика</h4>
                  </div>
                </div>{' '}
              </div>
              <div className="row" style={{ rowGap: '24px' }}>
                <div className="col-6 col-lg-3 col-md-4">
                  <div class="overview-content products-awards pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div class="d-inline-flex flex-column align-items-center justify-content-center">
                      <div class="d-flex align-items-start flex-wrap">
                        <div>
                          <p class="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Количество оплат
                          </p>
                          <h1>{currencyFormat(dataUserStatistics?.referral_purchases)}</h1>
                        </div>
                      </div>
                    </div>{' '}
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>{' '}
                <div className="col-6 col-lg-3 col-md-4">
                  <div class="overview-content products-awards pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div class="d-inline-flex flex-column align-items-center justify-content-center">
                      <div class="d-flex align-items-start flex-wrap">
                        <div>
                          <p class="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Количество регистраций
                          </p>
                          <h1>{currencyFormat(dataUserStatistics?.referral_count)}</h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>{' '}
                <div className="col-6 col-lg-3 col-md-4">
                  <div class="overview-content products-awards pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div class="d-inline-flex flex-column align-items-center justify-content-center">
                      <div class="d-flex align-items-start flex-wrap">
                        <div>
                          <p class="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Итоговая комиссия
                          </p>
                          <h1>{currencyFormat(dataUserStatistics?.total_earnings)}</h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>{' '}
                <div className="col-6 col-lg-3 col-md-4">
                  <div class="overview-content products-awards pt-15 pb-15 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div class="d-inline-flex flex-column align-items-center justify-content-center">
                      <div class="d-flex align-items-start flex-wrap">
                        <div>
                          <p class="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Условия
                          </p>
                          <h6>1-ая оплата — 30%</h6>
                          <h6 style={{ marginTop: '2px' }}>после — 20%</h6>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OverviewMonth />
          <OverviewPartnerDashboard />
        </>
      )}

      <Helmet>
        <title>Главная</title>
      </Helmet>

      <div class="crm demo6 mb-25">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="breadcrumb-main">
                <h4 class="text-capitalize breadcrumb-title">Пошаговая инструкция</h4>
              </div>
            </div>
            <Steps />
          </div>
        </div>
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="breadcrumb-main">
                <h4 class="text-capitalize breadcrumb-title">Видео инструкция</h4>
              </div>
            </div>
            <div className=" checkout wizard7 global-shadow border-0 px-sm-50 px-20 pt-sm-50 py-30 mb-30 bg-white radius-xl w-100">
              {' '}
              <Video options={videoJsOptions} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverviewPage;
