import React from 'react';
import styles from './AccountLayout.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
const AccountLayout = ({ children, title }) => {
  const profileMenuData = [
    {
      label: 'Профиль',
      icon: '/img/svg/user.svg',
      link: '/account/edit',
    },
    {
      label: 'Сброс пароля',
      icon: '/img/svg/key.svg',
      link: '/account/reset-password',
    },

    {
      label: 'Настройки',
      icon: '/img/svg/settings.svg',
      link: '/account/setting',
    },
  ];
  const { pathname } = useLocation();
  const {
    userAuth: { data: userData },
  } = useSelector((state) => state.user);
  return (
    <>
      <div class="profile-setting">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-main">
                <h4 class="text-capitalize breadcrumb-title">Аккаунт</h4>
                <div class="breadcrumb-action justify-content-center flex-wrap">
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="#">
                          <i class="uil uil-estate"></i>Аккаунт
                        </a>
                      </li>
                      <li class="breadcrumb-item active" aria-current="page">
                        {title}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>

            <div class="col-xxl-9 col-lg-8 col-sm-7" style={{ margin: '0 auto', maxWidth: '770px' }}>
              {/* <div class="card banner-feature--18 new d-flex bg-white">
                <div class="row " style={{ flexWrap: 'nowrap' }}>
                  <div class="col-8">
                    <div class=" ">
                      <h4 class="banner-feature__heading color-dark">Настройки</h4>
                      <p class="banner-feature__para color-dark fs-13">
                        There are many variations of passages of Lorem Ipsum available,
                        <br />
                        ut the majority have suffered passages of Lorem Ipsum available alteration in some form
                      </p>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="banner-feature__shape">
                      <img src="/img/banner.png" alt="img" />
                    </div>
                  </div>
                </div>
              </div> */}
              <div
                className="d-flex card  "
                style={{
                  height: '203px',
                  backgroundImage: 'url(/img/banner-2.png)',
                  backgroundSize: 'cover',
                  backgroundPosition: '70% 0',
                  padding: '24px 0 0 25px',
                }}>
                <div className="row">
                  {' '}
                  <div className="col-8 col-md-7">
                    {' '}
                    <h2 class="banner-feature__heading color-white">Ваш профиль на Kaspi.kz</h2> <p class=" color-white fs-16 mt-10">Здесь вам необходимо ввести данные от кабинета продавца в kaspi.kz для синхронизации и корректной работы нашего робота.</p>
                  </div>
                  <div className="col-4 col-md-5">{/* <img src="/img/banner.png" class="w-100" alt="img" /> */}</div>
                </div>
              </div>
              <div class="mb-50">
                <div class="tab-content" id="v-pills-tabContent">
                  <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                    <div class="edit-profile mt-25">
                      <div class="card">
                        <div class="card-header px-sm-25 px-3">
                          <div class="edit-profile__title">
                            <h6>{title}</h6>
                          </div>
                        </div>
                        <div class="card-body">{children}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountLayout;
