import React from 'react';
import './APITokenShop.scss';
import Input from '../../site/Input/Input';
import { useForm } from 'react-hook-form';
import Button from '../../site/Button/Button';
const APITokenShop = () => {
  const form = useForm();
  return (
    <>
      <div class="card card-default card-md mb-4">
        <div class="card-header">
          <h6 class="color-danger " style={{ fontSize: '22px', textTransform: 'none' }}>
            У вас не подключен API токен каспи магазина
          </h6>
        </div>
        <div class="card-body" style={{ padding: '24px 15px 50px 15px' }}>
          <div className="form-basic">
            <div className="" style={{ display: 'grid', gridTemplateColumns: '1fr  auto', alignItems: 'end', gridGap: '13px' }}>
              <Input toolTip styleWrap={{}} styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="text" name={'email'} label={'API токен Kaspi'} classWrap={''} classInput="custom-input-home form-control-lg" placeholder={'Скопируйте API тоек каспи магазина'} />
              <Button style={{ alignSelf: 'end', height: '50px' }}>Записать</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default APITokenShop;
