import { useState } from "react";
import { Button, Modal, Input } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { regenerateBlockWithPrompt } from "../../../api";

export const RegenerateWithPrompt = ({ pageId, blockId }) => {
  const queryClient = useQueryClient();

  const { mutate, isPending: isRegeneratePending } = useMutation({
    mutationFn: regenerateBlockWithPrompt,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pages", pageId] });
    },
  });

  const [text, setText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    mutate({ blockId, text });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button loading={isRegeneratePending} onClick={showModal}>
        Пересоздать с текстом
      </Button>
      <Modal
        title="Пересоздать с текстом"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input.TextArea
          text={text}
          onChange={(event) => setText(event.target.value)}
        />
      </Modal>
    </>
  );
};
