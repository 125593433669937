import { Spin, Button, Flex, Input, Form, Table, Select } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { createDocument, getDocuments, getTemplates } from '../../../api';
import { useState } from 'react';

export const DocumentsPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { data: templates, isLoading: isTemplatesLoading } = useQuery({
    queryKey: ['templates'],
    queryFn: () => getTemplates(),
  });

  const { data, isLoading } = useQuery({
    queryKey: ['documents'],
    queryFn: () => getDocuments(),
  });
  const [showError, setShowError] = useState(false);
  const { mutate, isPending: isCreatePagePending } = useMutation({
    mutationFn: createDocument,
    onSuccess: (document) => {
      navigate(`/documents/${document.id}`);
      setShowError(false);
    },

    onSettled: () => {},
    onError: (err) => {
      setShowError(true);
      console.log(err.response.data?.tariff == 'No tariff found for the user.');
    },
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <Link to={`/documents/${text}`}>{text}</Link>,
    },
    {
      title: 'Тема',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'Предмет',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Класс',
      dataIndex: 'grade',
      key: 'grade',
    },
  ];

  return (
    <div className="App" style={{ marginTop: 32 }}>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          topic: '',
          grade: '',
          subject: '',
          template: '',
        }}
        onFinish={(values) => mutate(values)}>
        <Flex gap={20} align="end">
          <Form.Item label="Тема" name="topic" rules={[{ required: true }]}>
            <Input placeholder="Введите тему" />
          </Form.Item>
          <Form.Item label="Класс" name="grade" rules={[{ required: true }]}>
            <Input placeholder="Введите класс" />
          </Form.Item>
          <Form.Item label="Предмет" name="subject" rules={[{ required: true }]}>
            <Input placeholder="Введите предмет" />
          </Form.Item>

          <Form.Item label="Шаблон" name="template" rules={[{ required: true }]}>
            <Select
              loading={isTemplatesLoading}
              style={{ width: 120 }}
              placeholder="Выбрать шаблон"
              options={templates?.map((template) => ({
                value: template.id,
                label: template.title,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Button loading={isCreatePagePending} type="primary" htmlType="submit">
              Создать
            </Button>
          </Form.Item>
        </Flex>
      </Form>{' '}
      {showError && (
        <div className="text-danger" style={{ marginBottom: '10px', marginTop: '-10px' }}>
          Превышен лимит тарифа. Обновите тариф или переключитесь на другой.
        </div>
      )}
      {isLoading ? <Spin /> : <Table dataSource={data} columns={columns} />}
    </div>
  );
};
