import React, { useEffect, useState } from 'react';
import styles from './PricingPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { tariffGetList } from '../../../redux/actions/tariff/tariffGetList';
import Tariff from '../../../components/site/Tariff/Tariff';
import { Helmet } from 'react-helmet';
import Loading from '../../../components/site/Loading/Loading';
import moment from 'moment';
import { tariffPayment } from '../../../redux/actions/tariff/tariffPayment';
const PricingPage = () => {
  const dispatch = useDispatch();
  const {
    tariffGetList: { data: tariffList },
    tariffPayment: { data: tariffPaymentData, loading: tariffPaymentLoading },
  } = useSelector((state) => state.tariff);
  const {
    userProfile: { data: userProfile },
  } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(tariffGetList());
  }, []);
  useEffect(() => {
    if (tariffPaymentData) {
      window.location.href = tariffPaymentData?.payment_link;
    }
  }, [tariffPaymentData]);
  const [periodTariff, setPeriodTariff] = useState(false);
  return (
    <>
      <Helmet>
        <title>Тарифы</title>
      </Helmet>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 mb-30 mt-30">
            <div class="card banner-feature banner-feature--5" style={{ background: moment(userProfile?.end_date).isBefore(moment()) ? 'rgba(255,15,15,0.6)' : '#00a5e0', minHeight: '180px' }}>
              <div class="banner-feature__shape">
                <img src="/img/svg/group3320.svg" alt="img" />
              </div>
              <div class="d-flex justify-content-center">
                <div class="card-body" style={{ paddingBottom: '45px', paddingTop: '20px' }}>
                  <h1 class="banner-feature__heading color-white">Тарифы</h1>
                  {userProfile?.tariff_info ? (
                    <>
                      {' '}
                      <div className=" d-flex align-align-items-center text-white" style={{ marginTop: '5px', opacity: 0.9 }}>
                        <div style={{ marginTop: '3px', marginRight: '5px' }}>{moment(userProfile?.end_date).isBefore(moment()) ? 'Неактивный тариф' : ' Активный тариф'}</div>
                        <span style={{}} class={`pricing__tag color-${moment(userProfile?.end_date).isBefore(moment()) ? 'danger' : 'primary'} rounded-pill  ${moment(userProfile?.end_date).isBefore(moment()) ? 'bg-white' : 'bg-white'}`}>
                          {userProfile?.tariff_info?.name}
                        </span>
                      </div>
                      {moment(userProfile?.end_date).isBefore(moment()) ? (
                        <div class=" text-white d-flex align-items-center" style={{ marginTop: '10px', fontSize: '14px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px' }}>
                            <path d="M15.09814,12.63379,13,11.42285V7a1,1,0,0,0-2,0v5a.99985.99985,0,0,0,.5.86621l2.59814,1.5a1.00016,1.00016,0,1,0,1-1.73242ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"></path>
                          </svg>
                          Истек {moment(userProfile?.end_date).format('DD.MM.YYYY')}
                        </div>
                      ) : (
                        <div class=" text-white d-flex align-items-center" style={{ marginTop: '10px', fontSize: '14px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px' }}>
                            <path d="M15.09814,12.63379,13,11.42285V7a1,1,0,0,0-2,0v5a.99985.99985,0,0,0,.5.86621l2.59814,1.5a1.00016,1.00016,0,1,0,1-1.73242ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"></path>
                          </svg>
                          Истекает {moment(userProfile?.end_date).format('DD.MM.YYYY')}
                        </div>
                      )}
                      <div
                        onClick={() => {
                          dispatch(tariffPayment({ tariff_id: userProfile?.tariff_info?.id, payment_period: 'month', new_subscription: 'false' }));
                        }}
                        className="text-white extend-btn"
                        style={{ textDecoration: 'underline', textUnderlineOffset: '4px', marginTop: '10px', cursor: 'pointer', userSelect: 'none' }}>
                        Продлить
                      </div>
                    </>
                  ) : (
                    <h6 class="text-white " style={{ marginTop: '5px', opacity: 0.6, textTransform: 'none' }}>
                      <i class="uil uil-exclamation-circle"></i> Активный тариф не выбран
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 mb-30 ">
              <ul class="ai-list mb-10 mt-10" style={{ userSelect: 'none' }}>
                <li
                  class="ai-list-item"
                  onClick={() => {
                    setPeriodTariff(false);
                  }}>
                  <span class={`monthly space-nowrap ${!periodTariff && 'active'}`}>Месяц</span>
                </li>
                <li class="ai-list-item">
                  <input
                    class="ail ail-light"
                    id="ai1"
                    type="checkbox"
                    name="intervaltype"
                    checked={periodTariff}
                    onChange={(e) => {
                      setPeriodTariff(e.target.checked);
                    }}
                  />
                  <label class="ail-btn" for="ai1" data-bs-target="#monthly"></label>
                </li>
                <li
                  class="ai-list-item"
                  onClick={() => {
                    setPeriodTariff(true);
                  }}>
                  <span class={`yearly space-nowrap ${periodTariff && 'active'}`}>Год</span>
                </li>
              </ul>
              {/* <div className="d-flex align-items-center justify-content-end" style={{ userSelect: 'none' }}>
                <div
                  className=""
                  style={{ fontSize: '17px', cursor: 'pointer', marginRight: '12px', ...(!periodTariff && { color: 'var(--color-primary)' }) }}
                  onClick={() => {
                    setPeriodTariff(false);
                  }}>
                  Месяц
                </div>
                <div class="form-check form-switch form-switch-primary form-switch-sm" style={{ transform: 'scale(1.1)' }}>
                  <input
                    style={{ cursor: 'pointer' }}
                    type="checkbox"
                    class="form-check-input"
                    id="switch-s1"
                    checked={periodTariff}
                    onChange={(e) => {
                      setPeriodTariff(e.target.checked);
                    }}
                  />
                  <label class="form-check-label" for="switch-s1"></label>
                </div>{' '}
                <div
                  className=""
                  style={{ fontSize: '17px', cursor: 'pointer', transform: 'translateY(2px)', marginLeft: '8px', ...(periodTariff && { color: 'var(--color-primary)' }) }}
                  onClick={() => {
                    setPeriodTariff(true);
                  }}>
                  Год
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div class="row">
          {tariffList?.map((item, index) => (
            <Tariff {...item} color={item?.color ?? 'primary'} isYear={periodTariff} active={userProfile?.tariff_info?.id == item?.id} />
          ))}
        </div>
        {tariffPaymentLoading && <Loading />}
      </div>
    </>
  );
};

export default PricingPage;
