import { api, pureApi } from './utils/api';

export const getTemplates = async () => {
  const { data } = await api.get(`/api/core/templates`);
  return data;
};

export const getDocument = async (id) => {
  const { data } = await api.get(`/api/core/templates/user/${id}`);
  return data;
};

export const getLessons = async (id) => {
  const { data } = await api.get(`/api/core/templates/user/${id}/lessons`);
  return data;
};

export const getDocuments = async () => {
  const { data } = await api.get(`/api/core/templates/user`);
  return data;
};

export const createDocument = async (payload) => {
  const { data } = await api.post(`/api/core/templates/user/`, payload);
  return data;
};

export const getBlocks = async () => {
  const { data } = await api.get(`/api/core/blocks`);
  return data;
};

export const addBlock = async (payload) => {
  const { data } = await api.post('/api/core/userblocks', payload);
  return data;
};

export const regenerateBlock = async (id) => {
  const { data } = await api.post(`/api/core/templates/block/${id}`);
  return data;
};
export const saveLessons = async ({ id, lessons }) => {
  const { data } = await api.post(`/api/core/templates/user/${id}/lessons/`, { lessons });
  return data;
};

export const regenerateBlockWithPrompt = async ({ blockId, text }) => {
  const { data } = await api.post(`/api/core/templates/block/${blockId}/regenerate_message/`, {
    text,
  });
  return data;
};

export const downloadFile = async (documentId) => {
  const { data } = await api.get(`/api/core/templates/user/${documentId}/format/docx`);
  return data;
};

export const registration = async (payload) => {
  const { data } = await pureApi.post(`/auth/registration/`, payload);
  return data;
};
